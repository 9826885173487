.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a1a1a;
  padding: 1em;
  margin-bottom: 1em;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.logo img {
  width: 50%;
  max-width: 200px;
  height: auto;
  position: absolute;
  left: 10px;
  top: -10px;
}

.title {
  margin: 0.5em 0;
  font-size: 2.5em;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
}

.navItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0.5em 1em;
  margin: 0.5em;
  color: #ffffff;
  font-size: 1em;
  font-family: 'Arial', sans-serif;
  position: relative;
}

.button:hover {
  text-decoration: underline;
}

.button.active {
  text-decoration: underline;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .logo img {
    width: 40%;
    position: absolute;
    transform: translate(-25%, -20%);
  }

  .title {
    font-size: 2em;
  }

  .button {
    padding: 0.4em 0.8em;
    font-size: 0.9em;
  }
}