.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
  }
  
  .profile-card {
    margin: 10px; 
  }
  
  @media screen and (max-width: 768px) {
    .profile-card {
      flex: 1 0 calc(50% - 20px); /* Auf 50% Breite ändern für kleine Bildschirme und -20px für den Abstand */
      margin: 10px; /* Kleinere Abstand zwischen den Profilen in der Handyansicht */
    }
  }
  